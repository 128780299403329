import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import HelpBox from '../common/HelpBox'

export interface FinishRecoverPassProps {
  withCashiers: boolean
  language: string
}
const FinishRecoverPass: FC<FinishRecoverPassProps> = ({ withCashiers, language }) => {
  const { t } = useTranslation()
  const helpLinks = {
    offices: { es: '/es/oficinas-cajeros/', eu: '/eu/bulegoak-kutxazainak/', ca: '/ca/oficines-caixes/' },
  }
  return (
    <div className="lk-bol-new-user" data-testid={`bol-recover-pass${withCashiers ? '-with-cashiers' : ''}`}>
      <p>{t('widgetBolNewUser.finishRecoverPass.intro')}</p>
      <div className="lk-bol-new-user__help-boxes">
        {withCashiers && (
          <HelpBox
            title={t('widgetBolNewUser.finishRecoverPass.cashiers.title')}
            body={t('widgetBolNewUser.finishRecoverPass.cashiers.text')}
            buttonText={t('widgetBolNewUser.finishRecoverPass.cashiers.button')}
            href={`${helpLinks.offices[language]}?tab=offices`}
          />
        )}
        <HelpBox
          title={t('widgetBolNewUser.finishRecoverPass.offices.title')}
          body={t('widgetBolNewUser.finishRecoverPass.offices.text')}
          buttonText={t('widgetBolNewUser.finishRecoverPass.offices.button')}
          href={`${helpLinks.offices[language]}?tab=cashiers`}
        />
        <HelpBox
          title={t('widgetBolNewUser.finishRecoverPass.byPhone.title')}
          body={t('widgetBolNewUser.finishRecoverPass.byPhone.text')}
        />
      </div>
    </div>
  )
}

export default FinishRecoverPass
