import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import AccessibleModal from '../../AccessibleModal/AccessibleModal'

export interface BolApiErrorMessageProps {
  errorCode: string
  onClose: () => void
}

const BolApiErrorMessage: FC<BolApiErrorMessageProps> = ({ errorCode, onClose }) => {
  const { t } = useTranslation()
  const [title, setTitle] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  useEffect(() => {
    let i18nTitle = t(`bolApiErrors.${errorCode}.title`)
    if (i18nTitle === `bolApiErrors.${errorCode}.title`) {
      i18nTitle = t('bolApiErrors.default.title')
    }
    setTitle(i18nTitle)

    let i18nMessage = t(`bolApiErrors.${errorCode}.message`)
    if (i18nMessage === `bolApiErrors.${errorCode}.message`) {
      i18nMessage = t('bolApiErrors.default.message')
    }
    setErrorMessage(i18nMessage)
  }, [errorCode, t])

  return (
    <AccessibleModal active={!!errorCode} heading={title} fixed onClose={onClose}>
      <div
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: errorMessage }}
      />
    </AccessibleModal>
  )
}

export default BolApiErrorMessage
