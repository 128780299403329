import './WidgetBolNewUser.scss'

import { I18nextContext } from 'gatsby-plugin-react-i18next'
import React, { FC, useCallback, useContext, useState } from 'react'

import { BolStep, LastResponseData, OnSuccessCallbackArgs } from './common/types'
import BolRegisterWithCCForm from './steps/BolRegisterWithCCForm'
import BolRegisterWithoutCCForm from './steps/BolRegisterWithoutCCForm'
import FinishBetween14And17 from './steps/FinishBetween14And17'
import FinishLessThan14 from './steps/FinishLessThan14'
import FinishRecoverPass from './steps/FinishRecoverPass'
import IdForm from './steps/IdForm'
import OtpForm from './steps/OtpForm'

export interface WidgetBolNewUserComposerProps {
  currentStep: BolStep
  handleNextStep: (OnSuccessCallbackArgs) => void
  reset: () => void
  identifier: string
  lastResponse: LastResponseData
}

export const WidgetBolNewUserComposer: FC<WidgetBolNewUserComposerProps> = ({
  currentStep,
  handleNextStep,
  reset,
  identifier,
  lastResponse,
}) => {
  const { language } = useContext(I18nextContext)
  return (
    <>
      {currentStep === BolStep.IdForm && <IdForm onSuccess={handleNextStep} />}
      {currentStep === BolStep.OtpForm && (
        <OtpForm identifier={identifier} petitionId={lastResponse?.petitionId} onSuccess={handleNextStep} />
      )}
      {currentStep === BolStep.FinishLessThan14 && <FinishLessThan14 />}
      {currentStep === BolStep.FinishBetween14And17 && <FinishBetween14And17 />}
      {currentStep === BolStep.BolRegisterWithoutCCForm && (
        <BolRegisterWithoutCCForm identifier={identifier} petitionId={lastResponse?.petitionId} onSuccess={reset} />
      )}
      {currentStep === BolStep.BolRegisterWithCCForm && (
        <BolRegisterWithCCForm identifier={identifier} petitionId={lastResponse?.petitionId} onSuccess={reset} />
      )}
      {currentStep === BolStep.FinishNoCardWithBol && <FinishRecoverPass language={language} withCashiers={false} />}
      {currentStep === BolStep.FinishCardWithBol && <FinishRecoverPass language={language} withCashiers />}
    </>
  )
}

const WidgetBolNewUser: FC = () => {
  const [identifier, setIdentifier] = useState('')
  const [lastResponse, setLastResponse] = useState<LastResponseData>(null)
  const [currentStep, setCurrentStep] = useState<BolStep>(BolStep.IdForm)

  const handleNextStep = useCallback<OnSuccessCallbackArgs>((bolStep, data) => {
    if (data?.identifier !== undefined) {
      setIdentifier(data.identifier)
    }
    if (
      bolStep === BolStep.FinishLessThan14 ||
      bolStep === BolStep.FinishBetween14And17 ||
      bolStep === BolStep.FinishNoCardWithBol ||
      bolStep === BolStep.FinishCardWithBol
    ) {
      setIdentifier('')
    }
    setLastResponse(data)
    setCurrentStep(bolStep)
  }, [])

  const resetForm = useCallback(() => {
    setIdentifier('')
    setLastResponse(null)
    setCurrentStep(BolStep.IdForm)
  }, [])

  return (
    <div className="lk-bol-new-user">
      <WidgetBolNewUserComposer
        currentStep={currentStep}
        handleNextStep={handleNextStep}
        reset={resetForm}
        identifier={identifier}
        lastResponse={lastResponse}
      />
    </div>
  )
}
export default WidgetBolNewUser
