import '../AppWidgets.scss'

import { Button, Input } from '@lk/lk-design-system'
import React, { FC, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import appStoreIcon from '../../../../images/Widgets/app-store.svg'
import googlePlayIcon from '../../../../images/Widgets/google-play.png'
import LKApis from '../../../../services/lk-apis/LKApisProvider'
import { PostAppMessageResponse, PostLkPayMessageArgs } from '../../../../services/lk-apis/types'
import AccessibleModal from '../../../AccessibleModal/AccessibleModal'
import { DocWithSlug } from '../../../CTALink/CTALink'
import LegalCheckbox from '../../../LegalCheckbox/LegalCheckbox'
import LkCaptcha, { LkCaptchaRef } from '../../common/LkCaptcha'

export type KnownApps = 'bol' | 'lk-pay'
export interface WidgetDownloadAppProps {
  app: KnownApps
  legalLink: DocWithSlug
}

interface AppConfig {
  googlePlay: string
  appStore: string
  lkApi(data: PostLkPayMessageArgs, captcha: string): Promise<PostAppMessageResponse>
}

const WidgetDownloadApp: FC<WidgetDownloadAppProps> = ({ app, legalLink }) => {
  const { t } = useTranslation()
  const { lkWebApi } = useContext(LKApis)

  const [mobile, setMobile] = useState('')
  const [isLegalAccepted, setLegalAccepted] = useState(false)
  const [readyToSend, setReadyToSend] = useState(false)

  const [appConfig, setAppConfig] = useState<AppConfig>(null)

  useEffect(() => {
    if (app === 'lk-pay') {
      setAppConfig({
        googlePlay: 'https://play.google.com/store/apps/details?id=es.redsys.walletmb.app.laboralkutxa.pro',
        appStore: 'https://itunes.apple.com/us/app/laboral-kutxa-pay/id1156470958',
        lkApi: lkWebApi.postLkPayMessage,
      })
    }
    if (app === 'bol') {
      setAppConfig({
        googlePlay: 'https://play.google.com/store/apps/details?id=com.tecnocom.cajalaboral',
        appStore: 'https://apps.apple.com/es/app/clnet-movil/id523573864',
        lkApi: lkWebApi.postAppMessage,
      })
    }
  }, [app, lkWebApi])

  const [errors, setErrors] = useState('')
  const [responseStatus, setResponseStatus] = useState(0)
  const captchaRef = useRef<LkCaptchaRef>()

  const onMobileChange = useCallback(
    (e) => {
      e.persist()
      const mobileValidation = /^[67]\d{8}$/g
      const { value } = e.target
      const err = !value.match(mobileValidation) ? t('widgetDownloadApp.phoneValidation') : null
      setErrors(err)
      setMobile(value)
    },
    [t],
  )

  const onHandleLegal = useCallback((_name: string, legalAccepted: boolean) => {
    setLegalAccepted(legalAccepted)
  }, [])

  const onSendForm = useCallback(async () => {
    try {
      const captcha = await captchaRef.current.getCaptchaToken()
      await appConfig.lkApi(
        {
          mobile: `+34${mobile}`,
        },
        captcha,
      )
      setResponseStatus(200)
    } catch (err) {
      console.error(err)
      setResponseStatus(err.response?.status || 500)
    }
  }, [appConfig, mobile])

  useEffect(() => {
    setReadyToSend(!!mobile && isLegalAccepted && !errors)
  }, [isLegalAccepted, errors, mobile])

  return (
    <div className="lk-widget-app" data-testid="lk-widget-app">
      <AccessibleModal
        heading=""
        active={responseStatus === 200}
        onClose={() => {
          setResponseStatus(0)
          setMobile('')
        }}
        fixed
      >
        <p>{t('widgetDownloadApp.success')}</p>
      </AccessibleModal>
      <h2 className="lk-widget-app__title">{t(`widgetDownloadApp.${app}.title`)}</h2>
      <p className="lk-widget-app__subtitle">
        {t(`widgetDownloadApp.${app}.subtitle`)}
        <sup>*</sup>
      </p>
      <form className="lk-widget-app__form" aria-label="form">
        <Input
          name="telephone"
          type="tel"
          placeholder={t('widgetDownloadApp.inputPlaceholder')}
          onInput={onMobileChange}
          error={!!errors}
          errorMessage={errors}
          required
          value={mobile}
        />
        <LegalCheckbox
          fieldName="legal"
          legalTitle={t('widgetDownloadApp.legalTitle')}
          legalLink={`/${legalLink?.slug?.current}`}
          onLegalChange={onHandleLegal}
        />
        <Button type="button" label={t('widgetDownloadApp.button')} onClick={onSendForm} disabled={!readyToSend} />
        <LkCaptcha ref={captchaRef} />
      </form>
      <p className="text-xsmall">{t('widgetDownloadApp.freeSending')}</p>
      <div className="lk-widget-app__store">
        <p>{t('widgetDownloadApp.storeDownload')}</p>
        <div className="lk-widget-app__store__img">
          {appConfig?.googlePlay && (
            <a href={appConfig?.googlePlay} target="_blank" rel="noreferrer">
              <img src={googlePlayIcon} alt="Google Play" />
            </a>
          )}
          {appConfig?.appStore && (
            <a href={appConfig?.appStore} target="_blank" rel="noreferrer">
              <img src={appStoreIcon} alt="App Store" />
            </a>
          )}
        </div>
      </div>
    </div>
  )
}

export default WidgetDownloadApp
