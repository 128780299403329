import { BrandedIcon, Button, Icon } from '@lk/lk-design-system'
import React, { FC, useCallback, useEffect, useReducer, useState } from 'react'
import { useTranslation } from 'react-i18next'

import useCtaUtils from '../../hooks/useCtaUtils'
import * as SanitySchema from '../../lib/types/sanity-schema'
import { isSameTargetUrl } from '../../lib/urlUtils'
import CTALink from '../CTALink/CTALink'
import DecisionTreeBranchCard from './DecisionTreeBranchCard'
import DecisionTreeLeaveCard from './DecisionTreeLeaveCard'
import DecisionTreeProgress from './DecisionTreeProgress'
import { findNodeInPath, findParentNode, getLeaveLinks, TreeLeaveLink } from './DecisionTreeUtils'

export type DecisionTreeChoicesProps = Omit<SanitySchema.DecisionTree, '_type'> & {
  onRestartWizard: () => void
}

const removeDuplicates = (value, index, array) => array.indexOf(value) === index

const DecisionTreeChoices: FC<DecisionTreeChoicesProps> = ({
  title,
  question,
  showDetail,
  detailTitle,
  nodes,
  onRestartWizard,
}) => {
  const { t } = useTranslation()
  const { resolveCtaReferences } = useCtaUtils()
  const [currentKey, setCurrentKey] = useState('')
  const [isFinal, setIsFinal] = useState(false)

  const [currentNodes, setCurrentNodes] = useState([])
  const [currentQuestion, setCurrentQuestion] = useState(question)
  const [linkGroups, setLinkGroups] = useReducer(
    (_acc, curr) => curr.map((group) => group?.toLowerCase()).filter(removeDuplicates),
    [],
  )
  const [links, setCurrentLinks] = useReducer((_actual: TreeLeaveLink[], curr: TreeLeaveLink[]) => {
    const newLinks = []
    for (let i = 0; i < curr.length; i += 1) {
      const savedLink = newLinks.find((link) => isSameTargetUrl(link.cta, curr[i].cta))
      if (!savedLink) {
        newLinks.push({ ...curr[i], group: curr[i].group?.toLowerCase() })
      } else {
        savedLink.active = savedLink.active || curr[i].active
      }
    }
    return newLinks.map((link) => ({ ...link, cta: resolveCtaReferences(link.cta) }))
  }, [])

  useEffect(() => {
    const newNode = findNodeInPath(currentKey, nodes)
    const childNodes = newNode ? newNode.nodes : nodes
    setCurrentNodes(childNodes)
    const isLast = childNodes.find((node) => node._type === 'decisionTreeLeave')
    setIsFinal(isLast)
    if (isLast) {
      setCurrentQuestion(t('decisionTree.lastStep'))
    } else {
      const newQuestion = newNode ? newNode.question : question
      setCurrentQuestion(newQuestion)
    }
    if (showDetail) {
      const newLinks = getLeaveLinks(currentKey, nodes)
      setCurrentLinks(newLinks)
      setLinkGroups(newLinks.map((l) => l.group))
    }
  }, [currentKey, nodes, question, showDetail, t])

  const onBack = useCallback(() => {
    const parent = findParentNode(currentKey, nodes)
    setCurrentKey(parent?._key)
    if (!parent) {
      onRestartWizard()
    }
  }, [currentKey, nodes, onRestartWizard])

  return (
    <div className="decisionTree-choice">
      <p className="decisionTree-title">{title}</p>
      <div className="decisionTree-box">
        <div className="decisionTree-header">
          <Button
            onClick={onBack}
            size="sm"
            label={t('decisionTree.previous')}
            buttonRole="secondary"
            className="decisionTree-back"
          />
          <DecisionTreeProgress currentKey={currentKey} nodes={nodes} />
        </div>
        <div className="decisionTree-question ">{currentQuestion}</div>
        <div className="decisionTree-paths">
          {currentNodes &&
            currentNodes.map((node) =>
              node._type === 'decisionTreeBranch' ? (
                <DecisionTreeBranchCard node={node} key={node._key} onSelected={() => setCurrentKey(node._key)} />
              ) : (
                <DecisionTreeLeaveCard node={node} key={node._key} />
              ),
            )}
        </div>
        {isFinal && (
          <div className="decisionTree-footer">
            <Button
              onClick={() => {
                setCurrentKey('')
                onRestartWizard()
              }}
              size="sm"
              label={t('decisionTree.restart')}
              buttonRole="secondary"
              className="decisionTree-restart"
            />
          </div>
        )}
        {showDetail && !isFinal && (
          <div className="decisionTree-links">
            <div className="decisionTree-links__title">
              <BrandedIcon name="lightbulb" size={32} /> {detailTitle}
            </div>
            <div className="decisionTree-links__groups">
              {linkGroups.map((group) => (
                <div key={`decisionTreeGroupLink-${group}`} className="decisionTree-links__group">
                  <div className="decisionTree-links__group-title">{group}</div>
                  <ul className="decisionTree-links__group-links">
                    {links
                      .filter((l) => l.group === group)
                      .sort((a, b) => (a.title > b.title ? 1 : -1))
                      .map((link) => (
                        <li
                          key={link._key}
                          className={`decisionTree-links__link ${link.active ? 'decisionTree-links__link_active' : ''}`}
                        >
                          <CTALink
                            title={link.title}
                            pageRoute={link.cta.pageRoute}
                            link={link.cta.link}
                            route={link.cta.route}
                          />
                          <Icon name="chevron-right" size={24} />
                        </li>
                      ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default DecisionTreeChoices
