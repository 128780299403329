import './DecisionTree.scss'

import { Button } from '@lk/lk-design-system'
import React, { FC, useState } from 'react'

import * as SanitySchema from '../../lib/types/sanity-schema'
import Figure from '../Figure/Figure'
import TextWithIllustration from '../TextWithIllustration/TextWithIllustration'
import DecisionTreeChoices from './DecisionTreeChoices'

export type DecisionTreeProps = Omit<SanitySchema.DecisionTree, '_type'>

const DecisionTree: FC<DecisionTreeProps> = ({
  question,
  showDetail,
  detailTitle,
  nodes,
  title,
  intro,
  startButton,
}) => {
  const [showIntro, setShowIntro] = useState(true)
  return (
    <div className="decisionTree">
      {showIntro && (
        <div className="decisionTree-intro">
          {intro.illustration && (
            <div className="decisionTree-intro-image">
              <Figure node={intro.illustration?.image as SanitySchema.MainImage} />
            </div>
          )}
          <div className="decisionTree-intro-box">
            <p className="decisionTree-title">{title}</p>
            <TextWithIllustration text={intro.text} layout={intro.layout} />
            <Button size="lg" label={startButton} onClick={() => setShowIntro(false)} />
          </div>
        </div>
      )}
      {!showIntro && (
        <DecisionTreeChoices
          title={title}
          question={question}
          showDetail={showDetail}
          detailTitle={detailTitle}
          nodes={nodes}
          onRestartWizard={() => setShowIntro(true)}
        />
      )}
    </div>
  )
}
export default DecisionTree
