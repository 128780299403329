export type LastResponseData = {
  identifier?: string
  petitionId?: string
}

export type OnSuccessCallbackArgs = (bolStep: BolStep, data?: LastResponseData) => void

export interface BolFormCallbackProps {
  onSuccess: OnSuccessCallbackArgs
}

export interface BolFormProps extends BolFormCallbackProps {
  identifier: string
}

export enum BolStep {
  IdForm = 'IdForm',
  OtpForm = 'OtpForm',
  BolRecoverPassForm = 'BolRecoverPassForm',
  BolRegisterWithCCForm = 'BolRegisterWithCCForm',
  BolRegisterWithoutCCForm = 'BolRegisterWithoutCCForm',
  FinishLessThan14 = 'FinishLessThan14',
  FinishBetween14And17 = 'FinishBetween14And17',
  FinishNoCardWithBol = 'FinishNoCardWithBol',
  FinishCardWithBol = 'FinishCardWithBol',
}
