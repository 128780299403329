import React, { ReactElement, useContext } from 'react'

import SanitySchema from '../../../lib/types/sanity-schema'
import BolUserContext from '../../BolUser/BolUserProvider'
import CTALink from '../../CTALink/CTALink'
import PortableText from '../../PortableText/PortableText'
import BannerFigureWithText from '../banner-templates/BannerFigureWithText/BannerFigureWithText'
import BannerImage from '../banner-templates/BannerFigureWithText/BannerImage'
import BannerHtml from '../banner-templates/BannerHtml/BannerHtml'
import BannerImageAside from '../banner-templates/BannerImageAside/BannerImageAside'
import BannerImageRightImage from '../banner-templates/BannerImageAside/BannerImageAsideImage'
import BannerVideo from '../banner-templates/BannerVideo/BannerVideo'
import { BannerSlide } from '../types'

export interface SlideContext {
  breakpoints: any
  isLkModal: boolean
  isClnetTrue: boolean
}

const bannerAsArrayOfSlides = (slide) => {
  const slides = []
  switch (slide._type) {
    case 'banner':
      slide.hero.content.forEach((s) => slides.push(...bannerAsArrayOfSlides(s)))
      break
    case 'heroItem':
      slides.push(slide)
      break
    default:
      console.error(`unknown slide type "${slide._type}"`)
  }
  return slides
}

const isVisibleByUser = (slide: SanitySchema.HeroItem, isBolLoggedIn) =>
  !slide.filterUsers ||
  slide.filterUsers === 'all-users' ||
  (isBolLoggedIn && slide.filterUsers === 'registered') ||
  (!isBolLoggedIn && slide.filterUsers === 'non-registered')

const isVisibleByPage = (slide: SanitySchema.HeroItem, hideHeaderAndFooter, isLkModal) =>
  !slide.filterClnet ||
  slide.filterClnet === 'all-pages' ||
  (isLkModal && slide.filterClnet === 'clnet-modal') ||
  (!isLkModal && hideHeaderAndFooter && slide.filterClnet === 'clnet-true')

const isVisibleByDevice = (slide: SanitySchema.HeroItem, breakpoints) => {
  const isMobile = breakpoints.xs || breakpoints.mobile
  const isTablet = breakpoints.tablet
  const isLaptop = breakpoints.laptop
  const isDesktop = breakpoints.desktop

  return (
    !slide.filterDevices ||
    slide.filterDevices.includes('desktop-tablet-mobile') ||
    (isMobile && slide.filterDevices.includes('mobile')) ||
    (!isMobile && isTablet && slide.filterDevices.includes('tablet')) ||
    (isLaptop && slide.filterDevices.includes('laptop')) ||
    (isDesktop && slide.filterDevices.includes('desktop'))
  )
}

const processScheduledBanner = (banner: SanitySchema.SanityKeyed<SanitySchema.Banner>): Array<BannerSlide> => {
  const now = Date.now()
  if (!banner) {
    return []
  }

  return banner
    .map((item) => bannerAsArrayOfSlides(item))
    .flat()
    .reduce<BannerSlide>((previous: Array<BannerSlide>, actual: BannerSlide) => {
      const result = [...previous]
      if (now < Date.parse(actual.scheduledStartDate) || now > Date.parse(actual.scheduledEndDate)) {
        if (actual.scheduledEmptyBanner) {
          result.push(...bannerAsArrayOfSlides(actual.scheduledEmptyBanner))
        }
      } else {
        result.push(actual)
      }
      return result
    }, [])
    .map((item, index) => ({ ...item, _key: `slide-${index}` }))
}

const prepareSlide = (slide: SanitySchema.HeroItem, keyPrefix: string): ReactElement => {
  const { bannerType } = slide

  const body = !!slide?.body && <PortableText blocks={slide.body} />
  const footer = !!slide?.footer && <PortableText blocks={slide.footer} />
  const cta = slide.cta?.title?.trim() && (
    <CTALink
      kind={slide.cta.kind}
      route={slide.cta.route}
      title={slide.cta.title}
      pageRoute={slide.cta.pageRoute}
      link={slide.cta.link}
      buttonActionClass="lk-button lk-button--button lk-button--primary"
      buttonRole="primary"
    />
  )

  switch (bannerType) {
    case 'text-video':
      return <BannerVideo title={slide.title} body={body} footer={footer} cta={cta} videoUrl={slide.videoUrl} />
    case 'text-image':
      return (
        <BannerFigureWithText
          title={slide.title}
          body={body}
          footer={footer}
          cta={cta}
          img={slide.illustration && <BannerImage illustration={slide.illustration} />}
          key={`${keyPrefix}-${slide._key}`}
        />
      )
    case 'banner-text-image-right':
      return (
        <BannerImageAside
          title={slide.title}
          body={body}
          footer={footer}
          cta={cta}
          img={slide.illustration && <BannerImageRightImage illustration={slide.illustration} />}
          key={`${keyPrefix}-${slide._key}`}
          placeImageLeft={false}
        />
      )
    case 'banner-text-image-left':
      return (
        <BannerImageAside
          title={slide.title}
          body={body}
          footer={footer}
          cta={cta}
          img={slide.illustration && <BannerImageRightImage illustration={slide.illustration} />}
          key={`${keyPrefix}-${slide._key}`}
          placeImageLeft
        />
      )
    case 'html':
      return <BannerHtml slide={slide} />
    default:
      return null
  }
}

const useBannerUtils = () => {
  const { isBolLoggedIn } = useContext(BolUserContext)

  const isSlideVisible = (slide: SanitySchema.HeroItem, context: SlideContext) =>
    isVisibleByUser(slide, isBolLoggedIn) &&
    isVisibleByPage(slide, context.isClnetTrue, context.isLkModal) &&
    isVisibleByDevice(slide, context.breakpoints)

  return {
    prepareSlide,
    processScheduledBanner,
    isSlideVisible,
  }
}

export default useBannerUtils
