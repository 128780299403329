import { Accordion as AccordionDS, AccordionItem, useAccordion } from '@lk/lk-design-system'
import React, { FC } from 'react'

import useIsLoaded from '../../hooks/useIsLoaded'
import * as SanitySchema from '../../lib/types/sanity-schema'
import PortableText from '../PortableText/PortableText'

export type SanityBlockProps = Omit<SanitySchema.SanityBlock, '_type'>

export type AccordionProps = Omit<SanitySchema.Accordion, '_type'> & {
  content: AccordionItemProps
  itemsOpened: boolean
}

export type AccordionItemProps = Omit<SanitySchema.AccordionItem, '_type'> & {
  body?: Array<SanitySchema.SanityKeyed<SanityBlockProps>>
}

const Accordion: FC<AccordionProps> = ({ content, itemsOpened }) => {
  const { isLoaded } = useIsLoaded()
  const { isOpen, toggleItem } = useAccordion(
    content.map((item) => ({
      open: item.openByDefault ? item.openByDefault : itemsOpened,
    })),
  )

  return (
    <AccordionDS>
      {content.map((item, i) => (
        <AccordionItem
          key={item._key}
          open={isLoaded ? isOpen(i) : true}
          title={item.title}
          onButtonClick={() => toggleItem(i)}
        >
          {item.body ? <PortableText blocks={item.body} /> : null}
        </AccordionItem>
      ))}
    </AccordionDS>
  )
}

export default Accordion

export const AccordionBuilder = (item: AccordionProps) => <Accordion content={item.content} itemsOpened={false} />
